<template>
  <div ref="bPerformanceExportDropdown" class="performance-export-popup popup">
    <div class="dropdown-container">
      <div class="dropdown-option" @click="exportDownload()">
        Export
      </div>
      <div class="dropdown-option" @click="handleTableRowClick()">
        Table View
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BPerformanceExportDropdown',

  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},

  mounted() {
    setTimeout(() => {
      window.addEventListener('click', this.onClickOutsideEvent);
      window.addEventListener('keydown', this.onClickOutsideEventKey);
    }, 0);
  },

  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutsideEvent);
    window.removeEventListener('keydown', this.onClickOutsideEventKey);
  },

  methods: {
    close() {
      this.$emit('close');
    },

    // events
    onClickOutsideEvent(event) {
      const el = this.$refs.bPerformanceExportDropdown;
      if (el && !(el === event.target || el.contains(event.target))) {
        this.close();
      }
    },
    exportDownload() {
      this.$emit('download');
      this.$emit('close');
    },
    handleTableRowClick() {
      this.$emit('tableRowClick');
      this.$emit('close');
    },

    onClickOutsideEventKey(event) {
      if (event.key === 'Escape') {
        if (!(this.$el === event.target || this.$el.contains(event.target))) {
          this.close();
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.light-theme {
  .performance-export-popup {
    background: #f9f9f9;
    position: absolute;
    left: 0;
    z-index: 9;
    max-height: 250px;
    margin-top: 0;
    overflow: hidden;
    overflow-y: auto;
    background-color: rgba(45, 46, 53, 0.98);
    border-radius: 0 1px 4px 4px;
    box-shadow: 0 5px 3px 1px rgba(0, 0, 0, 0.1);
    border: none;
    .dropdown-container {
      background-color: #f9f9f9;
    }
    .dropdown-option {
      padding: 10px 17px;
      font-size: 14px;
      color: #555;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }
}
.performance-export-popup {
  position: absolute;
  top: 43px !important;
  left: 132px !important;
  z-index: 9;
  box-sizing: border-box;
  width: 230px;
  background: rgba(45, 46, 53, 0.98);
  border: 3px solid rgba(45, 46, 53, 0.98);
  border-radius: 3px;
  box-shadow: 0 5px 3px 1px rgb(0 0 0 / 10%);

  .dropdown-container {
    max-height: 300px;
    background-color: rgba(45, 46, 53, 0.98);
  }
  .dropdown-option {
    padding: 10px 17px;
    font-size: 13px;
    color: #cad1d6;
    cursor: pointer;
    &:hover {
      background-color: rgba(255, 255, 255, 0.04);
    }
  }
}
</style>
