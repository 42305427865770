<template>
  <div>
    <div class="table-class">
      <div class="table-h">
        <div
          v-for="(head, i) in headers"
          :key="i"
          :class="[
            showDiff ? 'table-h-col' : 'table-h-col-no-diff',
            ,
            head.name === 'Unique Reach %' ? 'w225' : '',
          ]"
        >
          <span v-if="head.disableSort" class="tb-export">
            {{ head.name === 'VCR' ? 'VCR / LTR' : head.name }}
          </span>
          <span
            v-else
            class="header-intip4 header-intip6 tb-export"
            :data-tool-tip="`Sort by ${head.hoverName || head.name}`"
            @click="sortData(head.sortKey, head.initialSortDirection || 'desc')"
          >
            {{ head.name === 'VCR' ? 'VCR / LTR' : head.name }}
            <font-awesome-icon :icon="['fa', 'sort']" class="cp-sort" />
          </span>
        </div>
        <div>
          <span
            class="header-intip4 header-intip6 header-intip-w70 header-export"
            @click="handleShowExportDropdown"
          >
            <font-awesome-icon :icon="['far', 'ellipsis-h']" class="cp-export" />
          </span>
        </div>
      </div>
    </div>

    <div class="card">
      <div v-if="!loading && data.length" id="creative-card-area" class="card-area">
        <div v-for="(entry, i) in sortedData" :key="i" class="card-row">
          <div v-if="entry.info" class="card-info">
            <div class="card-info-wrap">
              <div v-for="(inf, j) in entry.info" :key="j" class="info">
                <span v-if="inf.head === 'VCR'" class="head muted">VCR / LTR</span>
                <span v-else-if="inf.head === 'Video Completions'" class="head muted"
                  >Completions</span
                >
                <span v-else class="head muted">{{ inf.head }}</span>
                <b-numero
                  :value="inf.value"
                  :is-infinity="inf.isInfinity"
                  :type="inf.valueType || 'frequency'"
                />
              </div>
            </div>
          </div>
          <div
            v-if="entry.nameColumnStyle === 'logo'"
            class="card-col"
            :class="[showDiff ? 'w195' : 'w245']"
          >
            <img :src="entry.imgSrc" />
          </div>
          <span
            v-if="entry.nameColumnStyle === 'text'"
            class="card-col lineitem-title"
            :class="[showDiff ? 'w185' : 'w235']"
          >
            {{ entry.name }}
          </span>
          <div
            v-if="entry.nameColumnStyle === 'both'"
            class="card-col"
            :class="[showDiff ? 'w195' : 'w245']"
          >
            <div class="bt bt-1a">
              <img :src="entry.imgSrc" />
            </div>
            <div class="bt bt-1b">
              <div class="bt-title">{{ entry.name }}</div>
              <div class="bt-subtitle">{{ entry.subText }}</div>
            </div>
          </div>
          <!-- To rollback PS-733 changes
          <div
            v-if="entry.nameColumnStyle === 'preview'"
            class="card-col"
            :class="[showDiff ? 'w195' : 'w245']"
          >
            <div class="bt bt-1a preview" @click="onClickVideoPreview(entry.id)">
              <div class="vf-wrap">
                <iframe
                  :src="entry.previewUrl"
                  scrolling="no"
                  width=""
                  height=""
                  class="vidwrap vframe smframe"
                  frameborder="0"
                  style="pointer-events: none;"
                />
              </div>
            </div>
            <div class="bt bt-1b preview-margin">
              <div class="bt-title-preview">{{ entry.name }}</div>
            </div>
          </div>
          end of PS-733 changes-->
          <div class="performance-num-value">
            <b-numero
              class="card-col w75"
              :value="entry.value"
              :is-infinity="entry.isValueInfinity"
              :type="entry.valueType || 'frequency'"
            />
            <!-- showDiff is used to check if date comparison is enabled on date-picker on dashboard -->
            <k-diff-pill
              v-if="showDiff"
              class="card-col w55"
              :value="entry.change"
              :is-infinity="entry.isChangeInfinity"
              :reverse-colors="entry.reverseColors"
            />
          </div>
        </div>
      </div>
      <span v-if="!loading && !data.length" class="no-data">No data is available to show</span>
      <b-loading-spinner v-else-if="loading" class="txt-center" />
    </div>
    <b-performance-export-dropdown
      v-if="showExportDropdown"
      @close="showExportDropdown = false"
      @download="download"
      @tableRowClick="tableRowClick"
    />

    <!-- To rollback PS-733 changes
      <b-modal
      v-if="showCreativePreview"
      :enable-click-out-side="true"
      @close="showCreativePreview = false"
    >
      <div slot="modal-body" class="modal-wrapper-box">
        <div class="modal-container">
          <div class="modal-header">
            <span class="com-title">Preview of Creative ID - {{ previewCreativeId }}</span>
          </div>
          <div class="modal-body">
            <div id="creative-preview-loader" class="creative-preview-loader-css">
              <b-loading-spinner class="txt-center" />
            </div>
            <iframe
              class="info-modal-iframe"
              :src="`https://creative-preview-an.com/cached/creative/${previewCreativeId}`"
              width="100%"
              height="auto"
              onload="document.getElementById('creative-preview-loader').style.display='none';"
            />
          </div>
        </div>
      </div>
    </b-modal>
    end of PS-733 changes-->
  </div>
</template>

<script>
import formatNumber, { formatNumberAsFullNumber, formattedNumberValue } from '~/util/numeral';
import BNumero from '~/components/elements/b-numero.vue';
import KDiffPill from './k-diff-pill.vue';
import { downloadCSVFile } from '~/util/utility-functions';
import BPerformanceExportDropdown from './b-performance-export-dropdown.vue';

export default {
  components: {
    KDiffPill,
    BNumero,
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    // To rollback PS-733 changes
    // BModal: () => import(/* webpackChunkName: "b-modal" */ '~/components/elements/b-modal.vue'),
    BPerformanceExportDropdown,
  },
  props: {
    data: {
      type: Array,
      required: false,
      default: () => [],
    },
    customHeaders: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDiff: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataGroupedBy: {
      type: String,
      required: false,
      default: null,
    },
    /* To rollback PS-733 changes
    showPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
    end of PS-733 changes */
  },
  data: () => {
    return {
      sortKey: '',
      sortDirection: 'desc',
      showExportDropdown: false,
      /* To rollback PS-733 changes
      showCreativePreview: false,
      previewCreativeId: null,
      end of PS-733 changes */
    };
  },
  computed: {
    headers() {
      const clonedCustomHeaders = [...this.customHeaders];
      if (this.showDiff) {
        clonedCustomHeaders.push({
          name: ' ',
          sortKey: 'change',
          hoverName: 'Change',
          disableSort: true,
        });
      }
      return clonedCustomHeaders;
    },
    sortedData() {
      const rows = [...this.data];
      if (this.sortKey === 'name') {
        return this.sort(rows);
      }
      return this.sortInfinity(rows);
    },
  },
  methods: {
    /* To rollback PS-733 changes
    onClickVideoPreview(id) {
      this.showCreativePreview = true;
      this.previewCreativeId = id;
    },
    onClickPreviewClose() {
      this.showCreativePreview = false;
    },
    end of PS-733 changes */
    close() {
      this.$emit('close');
    },
    tableRowClick() {
      this.$store.dispatch('common/toggleShowExportTable', true);
      this.$store.dispatch('common/setSelectedExportTableRow', this.dataGroupedBy);
    },
    download() {
      if (!this.loading && !this.data.length) {
        return;
      }
      const csvData = [];

      try {
        const header = {};
        header.f1 = 'Name';
        this.data[0]?.info?.forEach((col, i) => {
          let colHead = col.head;
          if (col.head === 'Video Completions') {
            colHead = 'Completions';
          }
          if (col.head === 'VCR') {
            colHead = 'VCR / LTR';
          }
          header[`f${i + 2}`] = colHead;
        });
        csvData.push(header);

        this.data.forEach((row) => {
          const line = {};
          line.f1 = row.name;
          row.info.forEach((col, i) => {
            const fn =
              col.valueType.toUpperCase() === 'PERCENTAGE'
                ? formatNumber
                : formatNumberAsFullNumber;
            const formatted = fn(col.value, col.valueType);
            line[`f${i + 2}`] = formattedNumberValue(formatted);
          });
          csvData.push(line);
        });

        const fileName = `export_${this.dataGroupedBy}.csv`;
        downloadCSVFile(csvData, fileName.replace(/ /g, '_').toLowerCase());
      } catch (err) {
        console.error('error downloading file ->', err);
        throw err;
      }
    },
    handleShowExportDropdown() {
      this.showExportDropdown = true;
    },
    sortData(key, sortDirection) {
      if (this.sortKey !== key) {
        this.sortDirection = sortDirection;
      }
      this.sortKey = key;
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      // Perform sorting based on key
      this.rows?.sort((a, b) => {
        const aValue = parseFloat(a[key].replace('%', ''));
        const bValue = parseFloat(b[key].replace('%', ''));
        if (this.sortDirection === 'asc') {
          return aValue - bValue;
        }
        return bValue - aValue;
      });
    },
    sort(rows) {
      return rows.sort((a, b) => {
        let result = 1;

        if (this.sortDirection === 'desc') {
          result = -1;
        }
        if (a[this.sortKey] < b[this.sortKey]) {
          return -1 * result;
        }
        if (a[this.sortKey] > b[this.sortKey]) {
          return 1 * result;
        }
        return 0;
      });
    },
    sortInfinity(rows) {
      const infinityKey = this.sortKey === 'change' ? 'isChangeInfinity' : 'isValueInfinity';

      // 1. Separate out rows with infinity value
      const infinityRows = rows.filter((r) => r[infinityKey]);
      const rowsToSort = rows.filter((r) => !r[infinityKey]);

      // 2. Sort the remaining rows
      const sortedRows = this.sort(rowsToSort);

      // 3. Add rows with infinity value back to the sorted rows so that they always appear at bottom
      infinityRows.forEach((r) => sortedRows.push(r));

      return sortedRows;
    },
    formatNumber,
  },
};
</script>

<style lang="scss" scoped>
.performance-num-value .positive-color.no-bg,
.performance-num-value .negative-color.no-bg {
  margin-top: unset !important;
}
::v-deep.negative-color.no-bg {
  font-size: 12px !important;
}
.card-col ::v-deep.big,
.card-col ::v-deep.small {
  font-size: 16px !important;
  color: #bac0c5 !important;
}
.intip4 {
  position: relative;
  top: 0;
}
.intip4[data-tool-tip]::after {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 99;
  display: block;
  width: 100px;
  padding: 10px 12px;
  margin-left: -50px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: center;
  pointer-events: none;
  content: attr(data-tool-tip);
  background-color: #fdea85;
  border-radius: 3px;
  -webkit-transition: ease 0.3s;
  transition: ease 0.3s;
  -webkit-transform: scale(0);
  transform: scale(0);
  transform-origin: bottom center;
}
.intip4[data-tool-tip]::after {
  width: 155px;
  margin-left: -70px;
  font-size: 12px;
  color: #222;
  pointer-events: none;
  background-color: rgba(245, 245, 250, 0.9);
}
.intip4[data-tool-tip]:hover::after {
  bottom: 115%;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.header-intip4 {
  position: relative;
  top: 0px;
  display: inline-block;
}
.header-intip4[data-tool-tip]::after {
  position: absolute;
  bottom: 0%;
  left: 0;
  z-index: 99;
  display: inline-block;
  width: 100px;
  padding: 10px 12px;
  margin-left: -65px;
  font-size: 12px;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: center;
  pointer-events: none;
  content: attr(data-tool-tip);
  background-color: #35363c;
  border-radius: 3px;
  -webkit-transition: ease 0.3s;
  transition: ease 0.3s;
  -webkit-transform: scale(0);
  transform: scale(0);
  transform-origin: bottom center;
}
.header-intip4[data-tool-tip]::after {
  width: 140px;
  margin-left: -75px;
  font-size: 12px;
  color: #222;
  pointer-events: none;
  background-color: rgba(245, 245, 250, 0.9);
}
.header-intip6[data-tool-tip]::after {
  background-color: rgba(245, 245, 250, 0.9);
  color: #222;
  width: 82px;
  bottom: 100%;
  margin-left: -35px !important;
  font-size: 12px;
  font-weight: 600;
  padding: 6px;
  text-align: center;
  pointer-events: none;
}
.header-intip6[data-tool-tip]:hover::after {
  transform: scale(1);
  bottom: 145%;
}
.header-intip-w70[data-tool-tip]::after {
  width: 70px;
}
::v-deep span.big {
  font-size: 14px !important;
}
::v-deep span.small {
  font-size: 14px !important;
}
.light-theme span.no-data {
  color: #444;
}
span.no-data {
  position: absolute;
  width: 100%;
  font-size: 0.8rem;
  line-height: 100px;
  color: #bac0c5;
  text-align: center;
}
.loading-spinner {
  width: 100px;
  height: 100px;
}
.table-class {
  margin-top: 30px;
}
.light-theme .table-h {
  color: #212325;
  .table-h-col-no-diff {
    color: #212325;
  }
  svg {
    color: #212325;
  }
}
.table-h {
  display: inline-block;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.2em;
  color: #97a7bb;
  background-color: transparent;
  border-bottom: 1px solid rgba(155, 155, 155, 0.1);
}
.table-h-col {
  display: inline-block;
  float: left;
  width: 85px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.2em;
  color: #97a7bb;
  &:first-child {
    width: 195px;
  }
  &:last-child {
    float: left;
    width: 25px;
    padding-right: 0;
    text-align: center;
  }
}
.table-h-col-no-diff {
  display: inline-block;
  float: left;
  width: 85px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.2em;
  color: #97a7bb;
  cursor: pointer;
  &:first-child {
    width: 245px;
  }
  &:last-child {
    padding-right: 0;
  }
}
.table-h-col-no-diff.w225 {
  margin-left: -60px;
  width: 110px;
  .header-intip6[data-tool-tip] {
    &:hover {
      &::after {
        left: 50px;
      }
    }
  }
}
.table-h-col.w225 {
  margin-left: -35px;
  width: 105px;
  .header-intip6[data-tool-tip] {
    &:hover {
      &::after {
        left: 50px;
      }
    }
  }
}
.header-export {
  position: absolute;
  right: 17px;
  top: 11px;
}
.cp-sort {
  cursor: pointer;
  color: #69737d;
  font-size: 12px;
}
.cp-export {
  cursor: pointer;
  color: #97a7bb;
  font-size: 16px;
  font-weight: 900;
}
.cp-export:hover,
.cp-sort:hover {
  color: var(--primarycolor);
}

.text-r {
  text-align: right;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-external-link:before {
  content: '\f08e';
}
.fa-caret-down:before {
  content: '\f0d7';
}
.fa-caret-up:before {
  content: '\f0d8';
}
.card {
  height: 519px;
  overflow-x: hidden;
  overflow-y: auto;
}
.card-area {
  max-height: 549px;
}
.light-theme {
  .card-col ::v-deep.big,
  .card-col ::v-deep.small {
    color: #888 !important;
  }
}
.card-row {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 13px 5px 13px 15px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .card-info {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 0;
    height: 100%;
    opacity: 0;
    transition: all 0.3s ease;
    .card-info-wrap {
      display: none;
      width: 85%;
      height: 100%;
      padding: 3px 5px;
      overflow-x: scroll;
      overflow-y: hidden;
      color: white;
      white-space: nowrap;
      background-color: #313740;
      margin-left: 75px;
      padding-right: 30px;
      @-moz-document url-prefix() {
        padding-right: 30px;
      }
      .info {
        display: inline-block;
        min-width: 55px;
        padding-right: 7px;
        padding-left: 7px;
        .head.muted {
          font-size: 13px;
          color: #8a94a5;
        }
        .intip4[data-tool-tip]::after {
          display: none;
        }
        &.big {
          font-size: 14px;
          color: #bac0c5;
        }
        &.small {
          font-size: 14px;
          color: #bac0c5;
        }
        &:last-child {
          width: 75px;
          padding-left: 0;
        }
      }
      &::before {
        position: absolute;
        display: inline-block;
        width: 0;
        height: 0;
        margin: -2px 0 0 -29px;
        content: '';
        border: 0 solid transparent;
        border-right-width: 0px;
        border-bottom: 59px solid #313740;
        border-left-width: 24px;
      }
    }
  }
  &:hover .card-info {
    width: 86%;
    opacity: 1;
    .card-info-wrap {
      display: block;
    }
  }
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.2);
}

* {
  scrollbar-width: thin;
  // scrollbar-color: #c5c5c5 #f6f6f6;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
}

*::-webkit-scrollbar-button {
  display: none;
}

.light-theme .card-row:hover {
  background-color: rgba(0, 0, 0, 0.06);
  .card-info {
    .card-info-wrap {
      color: #536176;
      background-color: #fff;
      &::before {
        border-bottom: 59px solid #fff;
      }
    }
    .card-info-wrap::-webkit-scrollbar-thumb:hover {
      background: rgba(111, 111, 111, 0.3) !important;
    }
  }
}
.card-row:hover {
  background-color: rgba(0, 0, 0, 0.2);
  .lineitem-title {
    &:after {
      background-color: inherit;
    }
    &:before {
      color: transparent;
    }
  }
}
.card-col {
  display: inline-block;
  font-size: 11px;
  line-height: 1.2em;
  word-break: break-all;
  vertical-align: top;
}
.card-col:last-child {
  float: right;
}
.w40 {
  width: 40px;
}
.w60 {
  width: 60px !important;
  padding-left: 10px;
}
.w65 {
  width: 65px !important;
  padding-left: 10px;
}
.w65-0 {
  width: 65px !important;
  padding-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.w75 {
  width: 75px !important;
}
.w55 {
  width: 55px !important;
  padding-left: 0;
}
.w55-0 {
  width: 55px !important;
  padding-left: 0;
}
.w45 {
  width: 45px !important;
  padding-left: 5px;
}
.w80 {
  width: 80px;
  padding-left: 10px;
}
.w130 {
  width: 130px;
}
.w180 {
  width: 180px;
}
.w210 {
  width: 210px;
}
.w195 {
  width: 195px;
}
.w185 {
  width: 185px;
  padding-right: 10px !important;
}
.w235 {
  width: 235px;
  padding-right: 10px !important;
}
.w245 {
  width: 245px;
}
.w225 {
  width: 225px;
}
.lineitem-title {
  position: relative;
  display: inline-block;
  max-height: 3.6em;
  padding-right: 0.2em;
  margin-right: 10px;
  overflow: hidden;
  font-size: 11px;
  line-height: 1.2em;
  color: #bac0c5;
  text-align: justify;
  vertical-align: top;
  &:before {
    /* absolute position */
    position: absolute;

    /* set position to right bottom corner of block */
    right: 0;
    bottom: 0;

    /* points in the end */
    content: '...';
  }

  /* hide ... if we have text, which is less than or equal to max lines */
  &:after {
    /* absolute position */
    position: absolute;

    /* set position to right bottom corner of text */
    right: 0;

    /* set width and height */
    width: 1em;
    height: 1em;
    margin-top: 0.2em;

    /* points in the end */
    content: '';

    /* bg color = bg color under block */
    background: #212429;
  }
}
.card-row img {
  width: 100%;
  max-width: 130px;
  height: auto;
}
.bt {
  display: inline-block;
  font-size: 12px;
  line-height: 1.2em;
  vertical-align: middle;
  color: #bac0c5;
}
.bt-1a img {
  width: 40px !important;
  height: 40px !important;
}
// .bt-1a.preview {
//   width: 40px;
//   vertical-align: text-top;
// }
.vf-wrap {
  display: inline-block;
  transform: scale(0.17);
  transform-origin: top left;
  position: absolute;
}
.bt-1b {
  margin-left: 10px;
  max-width: 140px;
}
/* To rollback PS-733 changes
.bt-1b.preview-margin {
  margin-left: 20px;
}
end of PS-733 changes */
.light-theme {
  .bt-title {
    color: #444;
  }
}
.bt-title {
  color: #fff;
  font-size: 16px;
  margin-bottom: 3px;
}
/* To rollback PS-733 changes
.bt-title-preview {
  font-size: 11px;
  line-height: 1.2em;
  color: #bac0c5;
}
end of PS-733 changes */
.bt-subtitle {
  color: #929fb2;
  font-size: 12px;
}
.tb-change.changeup {
  color: #20cb9a;
}
.tb-change.changedown {
  color: #ff3d57;
}
.tb-change {
  display: inline-block;
  width: 45px;
  text-align: center;
}
.bt-perf {
  position: relative;
  float: right;
  margin-left: 5px;
  font-size: 18px;
  color: #bac0c5;
}
.btlink {
  position: relative;
  top: -5px;
  right: -20px;
  margin: 0px 0px 0px 5px;
}

.txt-center {
  position: absolute;
  width: 365px;
  margin-top: 60px;
  font-size: 13px;
  color: #bac0c5;
  text-align: center;
}
/* To rollback PS-733 changes
::v-deep .modal-mask {
  .modal-container {
    background-color: #212429 !important;
    min-height: 400px;
    min-width: 500px !important;
  }
  .modal-wrapper {
    margin: 0 auto;
  }
}
::v-deep .modal-header {
  padding: 15px !important;
  margin-bottom: 0 !important;
  svg {
    cursor: pointer;
  }
}
.modal-wrapper-box {
  width: 100%;
  min-width: 500px;
  max-width: 650px;
  padding: 15px;
  margin: 0 auto;
  color: #666;
  background-color: #212429;
  font-size: 12px;
  line-height: 0.75rem;
  color: rgba(130, 130, 140, 0.8);

  .com-title {
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.1em;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: #fff;
    margin-bottom: 20px;
  }
  h2 {
    text-align: center;
    color: #fff;
    line-height: 1.3;
    padding-bottom: 10px;
  }
  p {
    border-top: 2px solid white;
    text-align: center;
    font-size: 18px;
    padding: 20px 0;
    margin: 0;
    color: #fff;
  }
  .modal-body {
    width: 600px;
  }
}
.info-modal-iframe {
  width: 100%;
  min-width: 450px;
  max-width: 500px;
  min-height: 330px;
  overflow: scroll;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.creative-preview-loader-css {
  margin: 0 auto;
  .txt-center {
    width: 600px !important;
  }
}
end of PS-733 changes*/
</style>
